import React, { Component } from 'react'
import { Redirect } from "react-router-dom";

class Logout extends Component {

  state = {
    redirect: false
   };

  constructor(props) {
      super(props)
      this.state = {
          users: [],
          message: null
      }
  }

  renderRedirect = () => {
    if(this.state.redirect){
     return (
       <Redirect
         to="/login"
         userInput={this.props.userInput}
       />
      );
    }
  };

  async componentDidMount() {
    setInterval(() => {
        this.setState({ redirect: true });
      }, 2000);
  }

  render() {
      return (
          <div>
            {this.renderRedirect()}
            Logging out...
          </div>
      );
  }

}

export default Logout;