import React from 'react';
import { Auth } from 'aws-amplify';

function Login() {

  function signIn() {
    Auth.federatedSignIn({ provider: "Okta-Internal" });
  }
  
  return (
    <div className="d-flex flex-column flex-root">    
      <div className="login login-5 login-signin-on d-flex flex-row-fluid" id="kt_login">
        <div className="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid" style={{backgroundImage: `url('/bg-2.jpg')`}}>
          <div className="login-form text-center text-white p-7 position-relative overflow-hidden">
            <div>
              <div className="d-flex flex-center mb-15">
                <div className="flex-column-auto mt-5 pb-lg-0 pb-10">
                  <img
                    src="/logo-sentia-icon-only.svg"
                    id="login_logo_sentia"
                    alt="Sentia Logo"
                  />
                </div>
              </div>

              <div className="login-signin">
                <div className="mb-15">
                  <h3 className="display-1 font-weight-bold mt-7 mb-5 text-white">
                    Welcome to GraphiQL Sentia Portal
                  </h3>
                  <p className="font-weight-bold font-size-lg text-white opacity-40">
                    A graphical interactive in-browser GraphQL IDE for SALA.
                  </p>
                </div>

                <div className="form-group text-center mt-8">
                  <button className="btn btn-pill btn-primary opacity-90 px-15 py-3 btn-lg font-weight-bold font-size-h4" onClick={() => signIn()}>
                    LOGIN WITH OKTA
                  </button>
                </div>

                <div className="mt-10">
                  <div className="opacity-40 font-weight-bold order-2 order-sm-1 my-2">
                    &copy; 2020 Sentia
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>    
    </div>    
  );
}

export default Login;